<template>
<!--  <list-content-->
<!--      v-bind:rows-data="tableData"-->
<!--      v-bind:columns="tableColumns"-->
<!--      v-bind:add-label="pageContent.add_label"-->
<!--      v-bind:title="pageContent.title"-->
<!--      v-bind:new-uri="pageContent.newUri"-->
<!--      v-bind:edit-uri="pageContent.editUri"-->
<!--      v-bind:view-slug="pageContent.viewSlug"-->
<!--      v-bind:meta="meta"-->
<!--      v-bind:show-message="showMessage"-->
<!--      v-bind:message="message"-->
<!--      @handlePageChange="handleCurrentChange"-->
<!--      @handleSizeChange="handleSizeChange"-->
<!--      @handleSearchData="performSearch"-->
<!--      @handleDeleteData="handleDelete"-->
<!--  ></list-content>-->
  <el-row :gutter="20">
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="heading">
        <h1>{{pageContent.title}}</h1>
      </div>
    </el-col>
    <el-row :gutter="20">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
      >
        <div v-if="showMessage">
          <el-alert
              title="{{message.message}}"
              type="{{message.messageType}}"
              effect="{{message.effect}}"
          />
        </div>
      </el-col>
    </el-row>
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
    </el-col>
  </el-row>
  <el-skeleton style="width: 100%;height: 300px;" :loading="loading" animated :count="3">
    <template #template>
      <div style="width: 100%;margin-top: 40px;">
        <el-skeleton-item variant="text" style="width:2%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:60%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:10%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
      </div>
    </template>
    <template #default>
      <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%;box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
          :row-class-name="tableRowClassName"
          :header-cell-class-name="tableCellClassName"
          :show-header="true"
          :default-expand-all="false"
      >
          <el-table-column key="name" label="Plan2" width="">
            <template #header>
              Plan
            </template>
            <template #default="scope">
              <span v-html="scope.row.name"></span>
            </template>
          </el-table-column>
          <el-table-column key="price" label="Price" width="180">
            <template #header>
              Price
            </template>
            <template #default="scope">
              <span style="text-transform: uppercase;">{{scope.row.price.price}} {{scope.row.price.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column key="interval" label="Interval" width="180">
            <template #header>
              Interval
            </template>
            <template #default="scope">
              <span style="text-transform: capitalize;" v-html="scope.row.price.interval"></span>
            </template>
          </el-table-column>
          <el-table-column key="status" label="Status" width="180">
            <template #header>
              Status
            </template>
            <template #default="scope">
              <span style="text-transform: capitalize;" v-html="scope.row.status"></span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            width="200"
        >
          <template #header>
            ACTIONS
          </template>
          <template #default="scope">
            <el-button
                       size="small"
                       type="danger"
                       @click="performDelete(scope.$index, scope.row)"
            >Cancel  Subscription</el-button>
          </template>
        </el-table-column>
<!--          <el-table-column type="expand">-->
<!--            <template #default="props">-->
<!--              <h2 style="margin: 20px 0;padding-left: 10px;">-->
<!--              Subscription Items-->
<!--              </h2>-->
<!--              <el-table-->
<!--                  ref="multipleTableItems"-->
<!--                  :data="props.row.items"-->
<!--                  style="width: 94%;-->
<!--                  margin-left: 3%;-->
<!--                  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);"-->
<!--                  :row-class-name="tableRowClassName"-->
<!--                  :header-cell-class-name="tableCellClassName"-->
<!--                  :show-header="true"-->
<!--              >-->
<!--                <el-table-column key="product" label="Product" width="">-->
<!--                  <template #header>-->
<!--                    Item-->
<!--                  </template>-->
<!--                  <template #default="scope">-->
<!--                    <span v-html="scope.row.product.name"></span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column key="price" label="Price" width="180">-->
<!--                  <template #header>-->
<!--                    Item Price-->
<!--                  </template>-->
<!--                  <template #default="scope">-->
<!--                    <span style="text-transform: uppercase;">{{scope.row.price.price}} {{scope.row.price.currency}}</span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column key="price" label="Interval" width="180">-->
<!--                  <template #header>-->
<!--                    Item Interval-->
<!--                  </template>-->
<!--                  <template #default="scope">-->
<!--                    <span style="text-transform: capitalize;" v-html="scope.row.price.interval"></span>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column key="quantity" label="Quantity" width="180">-->
<!--                  <template #header>-->
<!--                    Item Quantity-->
<!--                  </template>-->
<!--                  <template #default="scope">-->
<!--                    <span style="text-transform: capitalize;" v-html="scope.row.quantity"></span>-->
<!--                  </template>-->
<!--                </el-table-column>-->

<!--                <el-table-column-->
<!--                    align="center"-->
<!--                    width="200"-->
<!--                >-->
<!--                  <template #header>-->
<!--                  </template>-->
<!--                  <template #default="scope">-->
<!--                    <el-button-->
<!--                        size="small"-->
<!--                        type="danger"-->
<!--                        @click="performItemDelete(scope.$index, scope.row)"-->
<!--                    >Cancel Item</el-button>-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--              </el-table>-->
<!--            </template>-->
<!--          </el-table-column>-->
      </el-table>

    </template>
  </el-skeleton>
</template>
<script>
import SubscriptionService from "@/api/subscription.service";
// import ListContent from "@/components/ListContent/index.vue";
import BlogsService from "@/api/blogs.service";
  export default {
    name: "Billing",
    components: {
      // ListContent
    },
    data() {
      return {
        showAlertAdded: false,
        loading: false,
        tableColumns: [
          {'key':'name', 'label':'Plan', 'sortable':false, 'is_image': false, 'width':''},
          {'key':'price', 'label':'Price', 'sortable':false, 'is_image': false, 'width':'100'},
          {'key':'interval', 'label':'Interval', 'sortable':false, 'is_image': false, 'width':'100'},
          {'key':'status', 'label':'Status', 'sortable':false, 'is_image': false, 'width':102}
        ],
        pageContent: {
          add_label: 'Subscription',
          title: 'Subscription',
          newUri: '/subscription/add',
          editUri:'/subscription/edit',
          viewSlug:''
        },
        tableData: [],
        page: 1,
        search: '',
        meta: Object,
        showMessage: false,
        message: {message:'', messageType:'success', effect:'dark'}
      };
    },

	mounted() {
      this.getTableData()
  },
   methods: {
     getTableData(){
       this.loading = true
      return SubscriptionService.getSubscritions().then(response => {
          this.tableData = response.data.data
          this.meta = {totalPages: 1}
          this.loading = false
        });
    },
     performSearch(search) {
       // this.search = search.name;
       // this.getTableData();
     },
     handleDelete(data) {
       // let deleteRow = data.row;
       // this.showMessage = false;
       // if (data.indexNo >= 0) {
         // return BlogsService.delete(deleteRow).then(response => {
         //   if(response.status === 201) {
         //     this.message.message = "Blog Article deleted successfully";
         //     this.message.messageType = "success";
         //     this.message.effect = "dark";
         //     this.showMessage = true;
         //     this.getTableData();
         //   }
         // });
       // }
     },
     handleCurrentChange(val) {
       // this.page = val;
       // this.getTableData();
     },
     handleSizeChange(val){
       // this.getTableData();
     },
     tableRowClassName({row, rowIndex}) {
       if (row && (rowIndex % 2 === 1)) {
         return 'table-row success-row';
       }else {
         return 'table-row';
       }
     },
     tableCellClassName() {
       return 'tableHeading';
     },

    }
  }
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
.el-table :deep(.tableHeading) {
  background: #bebbc936 !important;
  text-transform: uppercase;
  font-size: .857rem;
  font-weight: 600;
  letter-spacing: .5px;
  color: #6e6b7b;
  font-family: Montserrat,Helvetica,Arial,serif;
  line-height: 1.45;
  height: 40px;
  padding: 0;
}
.el-table__row {
  height: 250px;
  margin: 20px 0;
}
.el-table ::v-deep(.el-table__row) {
  height: 100px !important;
  margin: 20px 0;
}
.el-table ::v-deep(.success-row) {
  //background: #f0f9eb61;
}
.el-pagination :deep(button) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .number) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .active) {
  background-color: #f00 !important;
}
.el-table :deep(.table-row td) {
  padding: 0 !important;
}
</style>
