import BaseService from "./base.service";

class SubscriptionService {
    getIntent() {
        return BaseService.apiGetRequest('auth/user/setup-intent');
    }
    savePaymentMethod(data) {
        return BaseService.apiPostRequest('auth/user/payments', data);
    }

    loadPaymentMethod() {
        return BaseService.apiGetRequest('auth/user/payment-methods');
    }

    removePaymentMethod(data) {
        return BaseService.apiPostRequest('auth/user/remove-payment', data);
    }

    updateSubscription(data) {
        return BaseService.apiPutRequest('auth/user/subscription', data);
    }

    getProducts() {
        return BaseService.apiGetRequest('auth/prices/public', null, null, false);
    }

    getSubscritions() {
        return BaseService.apiGetRequest('subscription', null, null, false);
    }

}
export default new SubscriptionService();
